import * as React from "react"
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Slide,
  StackDivider,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import {
  CloseIcon,
  EmailIcon,
  HamburgerIcon,
  PhoneIcon,
} from "@chakra-ui/icons"
import { Link as GatsbyLink } from "gatsby"
import { useLocation } from "@reach/router"
import { FaPen } from "react-icons/fa"

import Logo from "./logo"
import { NavItem, DesktopNavItems, INavGroup } from "../data/navItems"
import NewsletterForm from "./newsletterForm"
import { recaptcha as recaptchaV3 } from "@avldev/gatsby-active-campaign-forms"
import { siteKey } from "../config/recaptcha"

const hoverStyle = { background: `red.50`, color: `red.600` }

interface DesktopNavProps {
  latestBlog: { slug: string; title: string }
  onToggleDropdown: (isOpen: boolean) => void
}

function DesktopNav({
  latestBlog,
  onToggleDropdown,
}: DesktopNavProps): JSX.Element {
  const { isOpen, onToggle } = useDisclosure()
  const [showNewsletterInput, setShowNewsletterInput] = React.useState(false)
  const navRef = React.useRef(null)
  const { pathname } = useLocation()

  React.useEffect(() => {
    recaptchaV3.initRecaptchaV3(siteKey)
  }, [])

  const toggleDrawer = (): void => {
    onToggle()
    if (showNewsletterInput) {
      setShowNewsletterInput(false)
    }

    // invert value of isOpen here, as it changes *after* toggle is finished
    onToggleDropdown(!isOpen)
  }

  const toggleNewsletterInput = (): void => {
    setShowNewsletterInput(!showNewsletterInput)
  }

  const truncatedTitle =
    latestBlog.title.length > 25
      ? latestBlog.title.slice(0, 25) + `...`
      : latestBlog.title

  return (
    <>
      <Flex
        align="center"
        bg="white"
        h="4.5rem"
        justify="space-between"
        left="0"
        pos="absolute"
        ref={navRef}
        top="0"
        w="100vw"
        zIndex="docked"
      >
        <Link
          aria-label="Link to homepage"
          as={GatsbyLink}
          pl={6}
          pos="relative"
          to="/"
        >
          <Logo />
        </Link>
        <HStack
          bg="white"
          h="100%"
          justify="start"
          pos="relative"
          pr={6}
          spacing={[0, null, null, null, 4]}
        >
          {DesktopNavItems.firstLevel.map(
            (item: NavItem, i: number): JSX.Element => (
              <GatsbyLink key={i} to={item.slug}>
                <Button
                  colorScheme={pathname === item.slug ? `red` : `gray`}
                  variant="ghost"
                  _hover={hoverStyle}
                >
                  {item.label}
                </Button>
              </GatsbyLink>
            )
          )}
          <Button
            aria-label={isOpen ? `Close menu` : `Open menu`}
            as={IconButton}
            bg="white"
            color="gray.800"
            colorScheme="red"
            fontSize="24px"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={toggleDrawer}
            variant="ghost"
            _hover={hoverStyle}
          />
        </HStack>
      </Flex>
      <Slide direction="top" in={isOpen} style={{ zIndex: 9 }}>
        <Box
          bg="white"
          shadow="lg"
          transform={`translateY(${isOpen ? "4.5rem" : "0"})`}
        >
          <Flex
            align="center"
            direction="row"
            justify="space-evenly"
            maxW="1000px"
            mx="auto"
            p={6}
          >
            {DesktopNavItems.groups.map(
              (group: INavGroup, i: number): JSX.Element => (
                <VStack key={i}>
                  <Box d="flex" flexDir="column" justifyContent="start">
                    <Text fontSize="18px" fontWeight="bold" mb={1}>
                      {group.name}
                    </Text>
                    {group.members.map(
                      (item: NavItem, x: number): JSX.Element => {
                        const isActive = pathname === item.slug
                        return (
                          <Link
                            as={GatsbyLink}
                            color={isActive ? `red.500` : `black`}
                            key={x}
                            mt={1}
                            textDecoration={isActive ? `underline` : `none`}
                            to={item.slug}
                          >
                            {item.label}
                          </Link>
                        )
                      }
                    )}
                  </Box>
                </VStack>
              )
            )}
          </Flex>
          <Flex bg="gray.50" justify="center" overflow="hidden" p={6}>
            {showNewsletterInput ? (
              <HStack spacing={4}>
                <NewsletterForm key="form" />
                <Button
                  key="form-back-button"
                  onClick={toggleNewsletterInput}
                  variant="link"
                  size="sm"
                >
                  Go Back
                </Button>
              </HStack>
            ) : (
              <HStack align="center" divider={<StackDivider />} spacing="32px">
                <HStack spacing="6px">
                  <Tag colorScheme="orange" size="sm">
                    <TagLabel>NEW BLOG</TagLabel>
                  </Tag>
                  <Button
                    aria-label={`Read a blog titled ${latestBlog.title}`}
                    as={GatsbyLink}
                    leftIcon={<Icon as={FaPen} />}
                    colorScheme="red"
                    to={`/blog/${latestBlog.slug}/`}
                    variant="link"
                  >
                    {truncatedTitle}
                  </Button>
                </HStack>
                <Button
                  colorScheme="red"
                  leftIcon={<EmailIcon />}
                  onClick={toggleNewsletterInput}
                  variant="link"
                >
                  Register for Our Newsletter
                </Button>
                <Box key="call-link" pos="relative">
                  <Text
                    color="gray.500"
                    fontSize="xs"
                    fontWeight="semibold"
                    left="25px"
                    pos="absolute"
                    top="-15px"
                    textTransform="uppercase"
                  >
                    Call Us Now
                  </Text>
                  <Button
                    aria-label="Call us now"
                    as={Link}
                    colorScheme="red"
                    href="tel:8287745720"
                    leftIcon={<PhoneIcon />}
                    variant="link"
                  >
                    (828) 774-5720
                  </Button>
                </Box>
              </HStack>
            )}
          </Flex>
        </Box>
      </Slide>
    </>
  )
}

export { DesktopNav }
